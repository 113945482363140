(function () {
	const momentLocal = moment.locale();

	moment.updateLocale('en', {
		relativeTime: {
			future: "in %s",
			past: "%s",
			s: 'just now',
			ss: '%ds',
			m: "1m",
			mm: "%dm",
			h: "1h",
			hh: "%dh",
			d: "1d",
			dd: "%dd",
			M: "1mon",
			MM: "%dmon",
			y: "1y",
			yy: "%dy"
		}
	});
	moment.updateLocale('ru', {
		relativeTime: {
			future: 'через %s',
			past: '%s',
			s: 'только что',
			ss: '%dс',
			m: "1м",
			mm: "%dм",
			h: '1ч',
			hh: "%dч",
			d: '1д',
			dd: "%dд",
			M: '1мec',
			MM: "%dмec",
			y: '1г',
			yy: "%dг"
		}
	});

	moment.locale(momentLocal);
})();

function nn(v) {
	return v !== null && v !== undefined;
}

Math.sign = Math.sign || function (x) {
	x = +x; // преобразуем в число
	if (x === 0 || isNaN(x)) {
		return x;
	}
	return x > 0 ? 1 : -1;
}

function mutc() {
	return moment.utc();
}

function mday() {
	return moment().startOf('day');
}

function escapeHtml(string) {
	return String(string).replace(/[&<>"'`=\/]/g, function (s) {
		return escapeHtml.entities[s];
	});
}

function jqSingle(selector) {
	const jq = $(selector);
	return jq.length > 0 ? jq.get(0) : null;
}

escapeHtml.entities = {
	'&': '&amp;',
	'<': '&lt;',
	'>': '&gt;',
	'"': '&quot;',
	"'": '&#39;',
	'/': '&#x2F;',
	'`': '&#x60;',
	'=': '&#x3D;'
};

function stopEvent(e) {
	if (e.stopPropagation) e.stopPropagation();
	if (e.preventDefault) e.preventDefault();
	if (e.cancelBubble) e.cancelBubble();
	if (e.stopImmediatePropagation) e.stopImmediatePropagation();
	return false;
}

if (!$.isFunction(String.prototype.escapeHtml)) {
	String.prototype.escapeHtml = function () {
		return escapeHtml(this);
	};
}

if (!$.isFunction(Array.prototype.first)) {
	Array.prototype.first = function () {
		return this.length ? this[0] : undefined;
	};
}

if (!$.isFunction(Array.prototype.last)) {
	Array.prototype.last = function () {
		return this.length > 0 ? this[this.length - 1] : undefined;
	};
}

class Modaller {
	constructor(content) {
		this.closeHandler = null;

		this.content = $(content);
		this.content.on('hidden.bs.modal', () => {
			if (this.closeHandler !== null) {
				this.closeHandler(this);
			}
		});
	}

	show(callback) {
		this.content.one('shown.bs.modal', () => {
			if ($.isFunction(callback)) {
				callback(this);
			}
		});
		this.content.modal();
	}

	close() {
		this.content.modal('hide');
	}

	setCloseHandler(handler) {
		this.closeHandler = handler;
		return this;
	}

	addFooterLink(title, classes, href) {
		const footer = this.content.find('.modal-footer');

		$('<a class="' + classes + '"></a>')
			.attr('href', href)
			.text(title)
			.appendTo(footer);

		footer.show();
	}

	addFooterButton(title, classes, handler) {
		const footer = this.content.find('.modal-footer');

		$('<button class="' + classes + '"></button>')
			.text(title)
			.click(function () {
				handler();
				return false;
			})
			.appendTo(footer);

		footer.show();
	}

	setTitle(title) {
		const header = this.content.find('.modal-header');
		header.find('.modal-title').text(title);
		header.show();
	}

	centerFooterButtons() {
		this.content.find('.modal-footer').addClass('modal-footer-text-center');
	}

	static showRemote(href, modallerCallback, failureCallback) {
		const template = `
<div class="modal" tabindex="-1" role="dialog" >
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header" style="display: none;" >
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title"></h4>
      </div>
      <div class="modal-body"></div>
      <div class="modal-footer" style="display: none;"></div>
    </div>
  </div>
</div>        
        `;
		$
			.get(href)
			.then(function (html) {
				const modaller = new Modaller(template.replace(/<div class="modal-body">/, '<div class="modal-body">' + html));
				modallerCallback(modaller);
			})
			.catch(function (a) {
				($.isFunction(failureCallback) ? failureCallback : $.noop)(a);
			});
	}

	static showLocal(body, modallerCallback) {
		const template = `
<div class="modal" tabindex="-1" role="dialog" >
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header" style="display: none;" >
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title"></h4>
      </div>
      <div class="modal-body">${body}</div>
      <div class="modal-footer" style="display: none;"></div>
    </div>
  </div>
</div>        
        `;
		modallerCallback(new Modaller(template));
	}
}

class Texter {
	constructor(texts) {
		this.texts = texts || [];
	}

	get(key) {
		if (key in this.texts) {
			return this.texts[key];
		}
		return key;
	}

	put(key, value) {
		this.texts[key] = value;
	}
}

class Former {
	constructor(form) {
		this.form = form;
	}

	clearErrors() {
		this.bindErrors([]);
	}

	_el(selector) {
		return this.form.find(selector);
	}

	bindErrors(errors) {
		this._el('.form-group').removeClass('has-error');
		this._el('span.form-group-error').remove();

		(errors || []).forEach(item => {
			if (!item.field) {
				return;
			}
			const formGroup = this._el('[name=' + item.field + ']').closest('.form-group');

			formGroup.addClass('has-error');
			formGroup.append($('<span class="help-block form-group-error"/>').text(item.error));
		});
	}
}

class Toast {
	static success(text, title) {
		toastr.success(text, title, {
			positionClass: "toast-top-center",
			timeOut: "3000",
			showDuration: "200",
			hideDuration: "200"
		});
	}

	static error(text, title) {
		toastr.error(text, title, {
			positionClass: "toast-top-center",
			timeOut: "2000",
			showDuration: "200",
			hideDuration: "200"
		});
	}
}

class PBar {
	constructor(el) {
		this.el = el;
		this._resize();
	}

	set text(val) {
		this.el.find('.pbar-val').text(val);
		this._resize();
	}

	set percent(val) {
		this.el.find('.pbar-bar').width(val + '%');
		this._resize();
	}

	_resize() {
		this.el.each(function () {
			const el = $(this);
			el.find('.pbar-val').width(el.innerWidth());
		});
	}
}

class Btn {
	constructor(el) {
		this.el = $(el);
	}

	set progress(val) {
		this.el
			.toggleClass('btn-in-progress', val)
			.prop('disabled', val);
	}
}

class ToggleLink {
	constructor(el) {
		this.el = el;
		this.el.click(() => this.toggle());
		this.checkSupplier = null;
		this.uncheckSupplier = null;
	}

	toggle() {
		if (this._disable(true)) {
			this._touchUrl(this.el.hasClass('checked') ? this.uncheckSupplier : this.checkSupplier);
		}
	}

	whenOn(promiseSupplier) {
		this.checkSupplier = promiseSupplier;
		return this;
	}

	whenOff(promiseSupplier) {
		this.uncheckSupplier = promiseSupplier;
		return this;
	}

	_disable(state) {
		const current = this.el.prop('disabled');
		if (current === state) {
			return false;
		}

		this.el
			.prop('disabled', state)
			.toggleClass('disabled btn-in-progress', state);
		return true;
	}

	_touchUrl(promiseSupplier) {
		if (promiseSupplier === null) {
			return;
		}
		promiseSupplier(this.el)
			.then((newState) => {
				this.el.toggleClass('checked', !!newState);
				this.el.text(this.el.data(newState ? 'text-checked' : 'text-normal'));
			})
			.then(() => this._disable(false), () => this._disable(false));
	}

	removeHref() {
		this.el.removeAttr('href');
		return this;
	}
}

(function (app) {
	app.securedPostSupplier = function (url) {
		return function () {
			return app.requireLoggedAndPostForObject(url);
		}
	};

	app.requireLoggedAndPostForObject = function (url) {
		return app.requireLogged()
			.then(function () {
				return new Promise(function (resolve, reject) {
					const args = {type: "POST", url: url, headers: {}};
					args.headers['X-CSRF-TOKEN'] = app.csrf;
					$.ajax(args)
						.then(function (a) {
							resolve(a);
						})
						.catch(function () {
							reject();
						});
				});
			})
			.catch(() => {
			});
	};

	app.requireLogged = function () {
		return new Promise(function (resolve, reject) {
			if ('csrf' in app) {
				resolve(app.csrf);
			} else {
				$.get('/aj/csrf')
					.then(function (a) {
						const csrf = typeof a === 'string' ? a.trim() : "";
						if (csrf.length > 0) {
							app.csrf = csrf;
							resolve(csrf);
						} else {
							document.location.href = '/auth?redirectUrl=' + encodeURIComponent(document.location.href);
						}
					})
					.catch(function () {
						reject();
					});
			}
		});
	};

	app.requireCsrf = function () {
		return new Promise(function (resolve, reject) {
			if ('csrf' in app) {
				resolve(app.csrf);
			} else {
				$.get('/aj/csrf')
					.then(function (a) {
						const csrf = typeof a === 'string' ? a.trim() : "";
						app.csrf = csrf;
						resolve(csrf);
					})
					.catch(function () {
						reject();
					});
			}
		});
	};

	app.makePost = function (url, data, ajaxOpts) {
		return app.requireCsrf().then(function (csrf) {
			return $.ajax($.extend({type: "POST", url: url, headers: {'X-CSRF-TOKEN': csrf}, data: data}, ajaxOpts));
		});
	}
})(App);

(function (app) {
	const fallbackImage = '/static/theme/img/tr1.png';
	app.srcFallback = function (el, uri) {
		if (el.src === fallbackImage) {
			el.src = null;
			return;
		}

		if (!uri) {
			uri = fallbackImage;
		}

		el.src = uri;
	}
})(App);

class LongOp {
	constructor(opts) {
		this.delay = opts.delay | 0;
		this.done = $.isFunction(opts.done) ? opts.done : $.noop;
		this.error = $.isFunction(opts.error) ? opts.error : $.noop;
		this.before = $.isFunction(opts.before) ? opts.before : $.noop;
		this.promise = $.isFunction(opts.promise) ? opts.promise : $.noop;
		this.seed = 0;
	}

	run() {
		const args = arguments;
		this.before();

		const it = ++this.seed;
		setTimeout(() => {
			if (it !== this.seed) {
				return;
			}

			this.promise
				.apply(this, args)
				.then(
					response => {
						if (it === this.seed) {
							this.done(response);
						}
					},
					error => {
						if (it === this.seed) {
							this.error(error);
						}
					});
		}, this.delay)
	}
}

const hugos = (function () {
	let spans = $([]);

	function updateHugoTextAll() {
		spans.each(function () {
			updateHugoText(this);
		});
	}

	function updateHugoText(hugo) {
		const hugoEl = $(hugo), number = hugoEl.data('smart-hugo'), numberVal = Number(number);
		if (!nn(number) || number.length === 0 || isNaN(numberVal)) {
			return;
		}

		let hugoFw = hugoEl.data('smart-hugo-fw');
		if (!hugoFw) {
			const oldText = hugoEl.text();
			hugoFw = hugoEl.width();
			hugoEl.data('smart-hugo-fw', hugoFw);
			hugoEl.data('smart-hugo-original', oldText);
			hugoEl.text(oldText);
		}

		const spaceK = hugoFw / hugoEl.parent().width();
		if (spaceK < .8) {
			return;
		}
		hugoEl.text(Numbers.toHugo(numberVal, '', hugoEl.data('smart-hugo-original')));
	}

	$(window).resize(updateHugoTextAll);

	return {
		refresh: function () {
			spans = $('span[data-smart-hugo]');
			updateHugoTextAll();
		}
	}
})();

const Numbers = (function () {
	function numberLocale() {
		// const _locale = Cookies.get('_locale') || undefined;
		const _locale = App.numberLocale;
		return _locale;
	}

	const supportsLocaleOptions = !!(typeof Intl === 'object' && Intl && typeof Intl.NumberFormat === 'function');

	function _toLocaleString(val) {
		if (supportsLocaleOptions) {
			return val.toLocaleString(numberLocale());
		}
		return val.toLocaleString();
	}

	function _toLocaleStringWithDecimalPlaces(val, minDecimalPlaces, maxDecimalPlaces) {
		if (supportsLocaleOptions) {
			if (minDecimalPlaces) minDecimalPlaces = Math.min(minDecimalPlaces, 20);
			if (minDecimalPlaces) maxDecimalPlaces = Math.min(maxDecimalPlaces, 20);

			return val.toLocaleString(numberLocale(), {
				minimumFractionDigits: minDecimalPlaces,
				maximumFractionDigits: maxDecimalPlaces
			});
		}
		return val.toFixed(maxDecimalPlaces);
	}

	function toLocaleString(value, options) {
		const num = Number(value);
		if (isNaN(num)) {
			return value;
		}
		const minDecimalPlaces = options && options.minDecimalPlaces;
		const maxDecimalPlaces = options && options.maxDecimalPlaces;
		if (minDecimalPlaces === undefined || maxDecimalPlaces === undefined) {
			return _toLocaleString(num);
		}
		return _toLocaleStringWithDecimalPlaces(num, minDecimalPlaces, maxDecimalPlaces);
	}

	const expPattern = new RegExp(/(\d+)\.?(\d*)e([+-]*)(\d+)/i);
	const fixedPattern = new RegExp(/\d+\.?(\d*)/i);

	/**
	 * https://gist.github.com/jiggzson/b5f489af9ad931e3d186
	 * */
	function getDecimalsFromNumberString(numberString) {
		const expMatch = expPattern.exec(numberString);
		if (expMatch) {
			const coefficientFractionDigits = (expMatch[2] !== null ? expMatch[2].length : 0);
			const mantissaFractionCorrection = (expMatch[3] === '-' ? -1 : 1) * parseInt(expMatch[4]);
			return coefficientFractionDigits - mantissaFractionCorrection;
		}

		const fixedMatch = fixedPattern.exec(numberString);
		if (fixedMatch) {
			return (fixedMatch[1] !== null ? fixedMatch[1].length : 0);
		}
		return 0;
	}

	function getDecimals(n) {
		if (!nn(n)) {
			return null;
		}
		if (typeof n === 'string') {
			n = parseFloat(n);
		}
		if (!isNumber(n)) {
			return null;
		}
		return getDecimalsFromNumberString(n.toString());
	}

	function toFixed(n, decimals) {
		if (decimals === undefined) {
			decimals = getDecimals(n);
		}

		if (decimals === null) {
			return null;
		}

		let toLocaleStringOpts =  {minDecimalPlaces: decimals, maxDecimalPlaces: decimals};
		return toLocaleString(n, toLocaleStringOpts);
	}

	function isNumber(n) {
		return (n !== null && typeof n === 'number' && !isNaN(n));
	}

	function toPrecision(n, precision) {
		if (!isNumber(n)) {
			return '';
		}
		return toFixed(n, getDecimalsFromNumberString(n.toPrecision(precision)));
	}

	function formatNodes(selector) {
		if (selector === undefined) {
			selector = 'span[nf-value]';
		}
		$(selector).each(function () {
			const span = $(this);
			const number = parseFloat(span.attr('nf-value'));
			const format = span.attr('nf') || null;
			const decimals = span.attr('nf-decimals');
			const hasDecimals = decimals !== undefined && decimals !== null && decimals !== '';

			let text = '';
			switch (format) {
				case 'hugo':
					text = toHugo(number);
					break;
				case 'price':
					text = toPrice(number, hasDecimals ? parseInt(decimals) : undefined);
					break;
				case 'perc2':
					text = toPerc2(number);
					break;
				case 'fixed2':
					text = toLocaleString(number, {minDecimalPlaces: 2, maxDecimalPlaces: 2});
					break;
				case 'fixed1':
					text = toLocaleString(number, {minDecimalPlaces: 1, maxDecimalPlaces: 1});
					break;
				case 'int':
					text = toInt(number);
					break;
				default:
					text = toLocaleString(number, !hasDecimals ? undefined : {minDecimalPlaces: parseInt(decimals), maxDecimalPlaces: parseInt(decimals)});
					break;
			}
			span
				.removeAttr('nf-value nf')
				.text(text);
		});
	}

	function toHugoRoundTo(n, digits) {
		if (digits === 0) {
			return Numbers.toLocaleString(Math.round(n));
		} else {
			return Numbers.toLocaleString(n, {minDecimalPlaces: digits, maxDecimalPlaces: digits});
		}
	}

	function toHugoJoin3(prefix, n, suffix) {
		return prefix + n + suffix;
	}

	function toHugo(n, prefix, elseText) {
		if (prefix === undefined) {
			prefix = '';
		}

		if (n < 0) {
			prefix += '-';
		}

		n = Math.abs(n);

		const trillion = 1000 * 1000 * 1000 * 1000.0, trillionSuffix = 'T';
		// 100 trillion or more - display like '100T'
		if (n >= 100 * trillion) return toHugoJoin3(prefix, toHugoRoundTo(n / trillion, 0), trillionSuffix);
		// 10 trillion or more - display 1 fractional digit, like '10.3T'
		if (n >= 10 * trillion) return toHugoJoin3(prefix, toHugoRoundTo(n / trillion, 1), trillionSuffix);
		// 1 trillion or more - display 2 fractional digit, like '1.34T'
		if (n >= trillion) return toHugoJoin3(prefix, toHugoRoundTo(n / trillion, 2), trillionSuffix);

		const billion = 1000 * 1000 * 1000.0, billionSuffix = 'B';
		if (n >= 100 * billion) return toHugoJoin3(prefix, toHugoRoundTo(n / billion, 0), billionSuffix);
		if (n >= 10 * billion) return toHugoJoin3(prefix, toHugoRoundTo(n / billion, 1), billionSuffix);
		if (n >= billion) return toHugoJoin3(prefix, toHugoRoundTo(n / billion, 2), billionSuffix);

		const million = 1000 * 1000.0, millionSuffix = 'M';
		if (n >= 100 * million) return toHugoJoin3(prefix, toHugoRoundTo(n / million, 0), millionSuffix);
		if (n >= 10 * million) return toHugoJoin3(prefix, toHugoRoundTo(n / million, 1), millionSuffix);
		if (n >= million) return toHugoJoin3(prefix, toHugoRoundTo(n / million, 2), millionSuffix);

		const thousand = 1000.0, thousandSuffix = 'K';
		if (n >= 100 * thousand) return toHugoJoin3(prefix, toHugoRoundTo(n / thousand, 0), thousandSuffix);
		if (n >= 10 * thousand) return toHugoJoin3(prefix, toHugoRoundTo(n / thousand, 1), thousandSuffix);
		if (n >= thousand) return toHugoJoin3(prefix, toHugoRoundTo(n / thousand, 2), thousandSuffix);

		if (elseText !== undefined && elseText !== null) {
			return prefix + elseText;
		} else {
			return prefix + toHugoRoundTo(n, 0).toString();
		}
	}

	function toPerc2(value) {
		if (isNumber(value)) {
			return toLocaleString(value * 100, {minDecimalPlaces: 2, maxDecimalPlaces: 2}) + '%';
		}
		return '';
	}

	function toInt(value) {
		if (isNumber(value)) {
			return toLocaleString(Math.round(value));
		}
		return '';
	}

	function toPrice(value, decimals) {
		if (!isNumber(value)) {
			return '';
		}
		if (decimals !== undefined) {
			const toLocaleStringOpts = {minDecimalPlaces: decimals, maxDecimalPlaces: decimals};
			return toLocaleString(value, toLocaleStringOpts);
		}
		return toPrecision(value, 5);
	}

	return {
		toLocaleString: toLocaleString,
		toFixed: toFixed,
		toPrecision: toPrecision,
		formatNodes: formatNodes,
		toHugo: toHugo,
		isNumber: isNumber,
		toPerc2: toPerc2,
		toInt: toInt,
		toPrice: toPrice,
		getDecimals: getDecimals
	};
})();

App.formatters = {
	price: function (value) {
		return Numbers.toPrice(value);
	},
	// display `prec` faction digits
	fracN: function (value, fractions) {
		let opts = undefined;
		if (fractions > 0) {
			opts = {minDecimalPlaces: fractions, maxDecimalPlaces: fractions};
			return Numbers.toLocaleString(value, opts);
		}
		return Numbers.toLocaleString(Math.round(value), opts);
	},
	fixed: function (value) {
		return Numbers.toFixed(value);
	},
	frac2: function (value) {
		return Numbers.toLocaleString(value, {minDecimalPlaces: 2, maxDecimalPlaces: 2});
	},
	int: function (value) {
		return Numbers.toLocaleString(Math.round(value));
	},
	signum: function (value) {
		if (value > 0) return 1;
		if (value < 0) return -1;
		return 0;
	},
	signumInt: function (value) {
		if (value > 0) return '+' + value;
		if (value < 0) return '-' + Math.abs(value);
		return 0;
	},
	hugo: function (value) {
		if (Numbers.isNumber(value)) {
			return Numbers.toHugo(Number(value), '');
		}
		return '';
	},
	perc2: function (value) {
		return Numbers.toPerc2(value);
	},
	month: function (value) {
		return moment(new Date(value)).format('MMM YYYY');
	},
	quarter: function (value) {
		return moment(new Date(value)).format('Qo YYYY');
	}
};

$.views.converters(App.formatters);

App.renderHelpers = {
	math: {
		isNumber: function (n) {
			return Numbers.isNumber(n);
		}
	},
	words: {
		first: function (text) {
			if (text === undefined || text === null || text.length === 0) {
				return text;
			}
			const spaceIndex = text.indexOf(' ');
			return spaceIndex !== -1 && spaceIndex < text.length - 1 ? text.substring(0, spaceIndex) : text;
		}
	},
	time: {
		formatMilliAgoShort: function (timeMilli) {
			return moment(timeMilli).fromNow(true);
		}
	},
	chars: {
		count: function (n) {
			if (n === undefined || n === null) {
				return 0;
			}
			return n.toString().length;
		}
	},
	css: {
		emaColor: function (ema, price) {
			if (Numbers.isNumber(ema) && Numbers.isNumber(price)) {
				if (ema > price * 1.05) {
					return "signum-1";
				}
				if (ema < price * 0.95) {
					return "signum1";
				}
			}
			return '';
		},
		viColor: function (volatility) {
			const n = Number(volatility);
			if (Numbers.isNumber(n)) {
				if (n <= App.viLimitLow) return "vi-color-low";
				if (n <= App.viLimitMedium) return "vi-color-medium";
				if (n <= App.viLimitHigh) return "vi-color-high";
				return "vi-color-xhigh";
			}
			return '';
		}
	}
};

$.views.helpers(App.renderHelpers);

class TableSorter {
	constructor(selector, aftertablesort) {
		const self = this;

		this.$th = null;
		this.direction = null;
		this.column = null;

		this.table = $(selector)
			.stupidtable_settings({
				will_manually_build_table: true,
			})
			.stupidtable({
				"float": function (a, b) {
					a = parseFloat(a);
					b = parseFloat(b);
					a = isNaN(a) ? 0 : a;
					b = isNaN(b) ? 0 : b;
					return a - b;
				},
				"float-null-last": function (a, b) {
					a = parseFloat(a);
					b = parseFloat(b);
					a = isNaN(a) ? Number.MAX_VALUE : a;
					b = isNaN(b) ? Number.MAX_VALUE : b;
					return a - b;
				},
				"string": function (a, b) {
					a = typeof a === 'string' ? a.trim() : '';
					b = typeof b === 'string' ? b.trim() : '';
					return a.localeCompare(b);
				}
			})
			.bind('aftertablesort', function (event, data) {
				// data.column - the index of the column sorted after a click
				// data.direction - the sorting direction (either asc or desc)
				// data.$th - the th element (in jQuery wrapper)
				// $(this) - this table object
				self.$th = data.$th;
				self.direction = data.direction;
				self.column = data.column;

				const columnGroup = data.$th.data('sort-column');
				if (columnGroup) {
					data.$th.closest('tr').find('th[data-sort-column=' + columnGroup + ']')
						.data("sort-dir", data.direction)
						.addClass("sorting-" + data.direction);
				}

				if ($.isFunction(aftertablesort)) {
					aftertablesort(self);
				}
			});
	}

	clear() {
		this.direction = null;
		this.column = null;
		this.$th = null;
		this.table.find('thead th')
			.data('sort-dir', null)
			.removeClass('sorting-asc sorting-desc');
	}

	restore() {
		if (this.$th !== null) {
			this.$th.stupidsort(this.direction);
		}
	}

	rebuild_table_structure() {
		this.table.stupidtable_build();
	}

	asc(selector) {
		this.table.find('thead').find(selector).stupidsort('asc');
	}
}

class ValueFlash {
	show(last, next, span) {
		let changeClass = 0;

		if (last !== null && last !== undefined && !isNaN(last)) {
			let diff = (next - last) / last;
			if (Math.abs(diff) > ValueFlash.THRESHOLD) {
				changeClass = Math.sign(diff);
			} else {
				changeClass = 0;
			}
		}

		span.removeClass(ValueFlash.CLASSES_ALL);
		if (changeClass !== 0) {
			setTimeout(() => {
				span.addClass(ValueFlash.CLASSES[changeClass]);
			}, 1);
			return true;
		}
		return false;
	}
}

ValueFlash.THRESHOLD = 0.00005;
ValueFlash.CLASSES = [];
ValueFlash.CLASSES[-1] = 'color-red-highlight';
ValueFlash.CLASSES[0] = '';
ValueFlash.CLASSES[1] = 'color-green-highlight';
ValueFlash.CLASSES_ALL = ValueFlash.CLASSES.join(' ');

function initTooltips(el, conf, always) {
	if (App.showTooltips || always) {
		$(el).tooltip(conf);
	}
}

function destroyTooltips(el) {
	$(el).tooltip('destroy');
}

initTooltips.opts = function (override) {
	return $.extend({}, {
		delay: {show: 200, hide: 200},
		container: 'body',
		animation: false,
		placement: 'auto bottom'
	}, override);
};

App.toggleDark = function () {
	if (App.themeName === 'dark') {
		App.themeName = '';
	} else {
		App.themeName = 'dark';
	}
	const body = $('body');
	if (!body.is('.theme-fixed')) {
		body.toggleClass('theme-dark', App.themeName === 'dark');
	}

	App.selectTheme();
	$.get('/aj/site-theme?theme=' + App.themeName);
};

App.toggleUnit = function (unit) {
	const btn = new Btn('.btn-display-unit');
	btn.progress = true;

	$.get('/aj/site-unit?unit=' + unit).then(() => document.location.reload());
};

App.togglerInit = function (jqEl) {
	jqEl.find('input[type=checkbox][data-toggle^=toggle]').bootstrapToggle();
};

App.togglerCheck = function (jqEl, checked) {
	jqEl.bootstrapToggle(checked ? 'on' : 'off')
};

// register service worker. need to work with install banner
// https://developers.google.com/web/fundamentals/app-install-banners/
if ('serviceWorker' in navigator) {
	navigator.serviceWorker.register(`/sw.js?themePath=${App.themePath}`).then(
		registration => {
			console.log('ServiceWorker registration successful with scope: ', registration.scope);
		},
		err => {
			console.log('ServiceWorker registration failed: ', err);
		});
}

(function (app) {
	app.metrika = {
		reach: function (goal, params) {
			try {
				window.yaCounter.reachGoal(goal, params);
			} catch (e) {
				// do it quitely
			}
		},
		hitEvent: function (event, eventParams) {
			let query = '';
			if (eventParams) {
				query = Object.keys(eventParams)
					.map(k => `${k}=` + encodeURIComponent(eventParams[k]))
					.join("&");
			}
			const url = `/hitEvent/${event}?${query}`;
			try {
				window.yaCounter.hit(url);
			} catch (e) {
				// do it quitely
			}
		}
	};
})(App);


// setup ping method to keep session alive for online user. once per minute
(() => {
	setInterval(function () {
		$.get('/aj/ping?_t=' + new Date().getTime());
	}, 5 * 60 * 1000)
})();